import Api from '@/services/Index';

// Reservation
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/' + id);
}

export default {
    getAll,
    get,
}
