<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div>
                <ValidationObserver ref="reservationFormModalValidate">
                        <b-row>
                            <b-col cols="6">
                                <ValidationProvider name="name" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="formData.name" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="surname" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('last_name')">
                                        <b-form-input v-model="formData.surname" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="check_in_date" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('check_in_date')">
                                        <b-form-input v-model="formData.check_in_date" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="check_out_date" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('check_out_date')">
                                        <b-form-input v-model="formData.check_out_date" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="type" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('type')">
                                        <b-form-input v-model="formData.type" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="days" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('day')">
                                        <b-form-input v-model="formData.days" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="fee" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('price')">
                                        <b-form-input v-model="formData.fee" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="total_amount" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('total_amount')">
                                        <b-form-input v-model="formData.total_amount" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                </ValidationObserver>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <b-button @click="submitReservation()">
                    {{ $t('approved') }}
                </b-button>
                <b-button class="ml-5" variant="danger" @click="cancelReservation()">
                    {{ $t('declined') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    // Component


    // Services
    import MyReservationService from '@/services/MyReservationService'

    // Pages
    import Loading from '@/components/elements/Loading'


    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'


    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            Loading,
        },
        props: {
            reservationData: {
              type: Object,
              required: true
            }
        },
        data() {
            return {
                pageLoading: false,
                formData: this.reservationData,
            }
        },
        methods: {
        get(id) {
            MyReservationService.get(id)
                .then(response => {
                    this.formData = response.data.data;
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
}

    }
</script>